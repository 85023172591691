.filepond--root {
  font-size: 1.6rem !important;
  cursor: pointer;
}
.filepond--panel-root {
  border-radius: 0.5rem;
  border: 1px solid gray;
}
.filepond--wrapper {
  width: 100%;
}